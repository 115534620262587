// import logo from './logo.svg';
import './App.css';
import logo from './images/victoria-nails-logo.png';
import facebook from './images/facebook.png';
import instagram from './images/instagram.png';
import shop1 from './images/victoria_nails_001.jpg';
import shop2 from './images/victoria_nails_006.jpg';
import nails1 from './images/victoria_nails_007.jpg';
import nails2 from './images/victoria_nails_010.jpg';


function App() {
  return (
    <div className="App">
      <div className="App-box">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-title">
          Victoria Nails Paris
        </p>
      </div>
        <p className="App-subtitle">
          Paris | 8 Rue d'Alleray | Metro 12: Vaugirard
        </p>

        <div>
          <img src={shop1} className="App-photo"/>
          <img src={shop2} className="App-photo"/>
        </div>
        
        <a href="https://www.treatwell.fr/salon/victoria-nails-paris/" >
            <p className="">
              <b>
              Réservations: Treatwell.fr
              </b>
            </p>
          </a>
        <a href="tel: 0145314688"> 
          <p className="">
            <b>
              Tel: 01 45 31 46 88
            </b>
          </p>
        </a>

        <p className="App-subtitle">
          <b> 
            – TARIFS – 
          </b>
        </p>

        <table className="App-table">
          <tr>
            <p className="App-subtitle">
              – SOINS DES MAINS – 
            </p>
          </tr>
          <tr>
            <td>BEAUTÉ DES MAINS AVEC VERNIS COULEUR OU FRENCH</td>
            <td>22€</td>
          </tr>
          <tr>
            <td>BEAUTÉ DES MAINS AVEC VERNIS SEMI-PERMANENT OU FRENCH</td>
            <td>38€</td>
          </tr>
          <tr>
            <td>POSE DE VERNIS COULEUR OU FRENCH</td>
            <td>12€</td>
          </tr>
          <tr>
            <td>POSE DE VERNIS SEMI-PERMANENT COULEUR OU FRENCH</td>
            <td>27€</td>
          </tr>
          <tr>
            <p className="App-subtitle">
              – SOINS DES PIEDS – 
            </p>
          </tr>
          <tr>
            <td>BEAUTÉ DES PIEDS AVEC VERNIS COULEUR OU FRENCH</td>
            <td>33€</td>
          </tr>
          <tr>
            <td>BEAUTÉ DES PIEDS AVEC VERNIS SEMI-PERMANENT OU FRENCH</td>
            <td>47€</td>
          </tr>
          <tr>
            <td>POSE VERNIS COULEUR OU FRENCH</td>
            <td>12€</td>
          </tr>
          <tr>
            <td>POSE DE VERNIS SEMI-PERMANENT COULEUR OU FRENCH</td>
            <td>27€</td>
          </tr>
          <tr>
            <p className="App-subtitle">
            – ACRYLIQUES –
          </p>
          </tr>
          <tr>
            <td>POSE DE L'UNITÉ </td>
            <td>6€</td>
          </tr> 
          <tr>
            <td>POSE COMPLÈTE AVEC VERNIS COULEUR OU FRENCH</td>
            <td>50€</td>
          </tr> 
          <tr>
            <td>POSE COMPLÈTE AVEC VERNIS SEMI-PERMANENT OU FRENCH</td>
            <td>55€</td>
          </tr> 
          <tr>
            <td>REMPLISSAGE AVEC VERNIS COULEUR OU FRENCH</td>
            <td>45€</td>
          </tr> 
          <tr>
            <td>REMPLISSAGE AVEC VERNIS SEMI-PERMANENT OU FRENCH</td>
            <td>50€ </td>
          </tr> 
          <tr>
            <p className="App-subtitle">
              – GEL –
            </p>
          </tr>
          <tr>
            <td>POSE DE L'UNITÉ</td>
            <td>8€</td>
          </tr> 
          <tr>
            <td>POSE COMPLÈTE AVEC VERNIS COULEUR</td>
            <td>55€</td>
          </tr>
          <tr>
            <td>POSE COMPLÈTE AVEC VERNIS SEMI-PERMANENT</td>
            <td>60€</td>
          </tr> 
          <tr>
            <td>REMPLISSAGE AVEC VERNIS COULEUR</td>
            <td>50€</td>
          </tr> 
          <tr>
            <td>REMPLISSAGE AVEC VERNIS SEMI-PERMANENT</td>
            <td>55€</td>
          </tr> 
          <tr>
            <p className="App-subtitle">
              – FORFAIT –
            </p>
          </tr>
          <tr>
            <td>BEAUTÉ DES MAINS + BEAUTÉ DES PIEDS+VERNIS COULEUR</td>
            <td>50€</td>
          </tr> 
          <tr>
            <td>BEAUTÉ DES MAINS + BEAUTÉ DES PIEDS + VERNIS SEMI-PERMANENT</td>
            <td>80€</td>
          </tr> 
          <tr>
            <p className="App-subtitle">
              – SUPPLÉMENTS – 
            </p>
          </tr>
          <tr>
            <td>DÉPOSE SEMI-PERMANENT</td>
            <td>12€</td>
          </tr> 
          <tr>
            <td>DÉPOSE RÉSINE OU GEL</td>
            <td>20€</td>
          </tr> 
          <tr>
              <p className="App-subtitle">
              – ÉPILATIONS –
            </p>
          </tr>
          <tr>
            <td>SOURCIL</td>
            <td>12€</td>
          </tr> 
          <tr>
            <td>LÈVRE - MENTON - NEZ</td>
            <td>5€</td>
          </tr>
          <tr>
            <td>AISSELLES</td>
            <td>13€</td>
          </tr>
          <tr>
            <td>DOS</td>
            <td>25€</td>
          </tr>
          <tr>
            <td>CUISSE</td>
            <td>18€</td>
          </tr>
          <tr>
            <td>1/2 BRAS</td>
            <td>15€</td>
          </tr>
          <tr>
            <td>BRAS COMPLET</td>
            <td>20€</td>
          </tr>
          <tr>
            <td>1/2 JAMBE</td>
            <td>17€</td>
          </tr>
          <tr>
            <td>JAMBES COMPLÈTES</td>
            <td>30€</td>
          </tr>
          <tr>
            <td>VENTRE</td>
            <td>12€</td>
          </tr>
          <tr>
            <td>MAILLOT SIMPLE</td>
            <td>15€</td>
          </tr>
          <tr>
            <td>MAILLOT ÉCHANCRÉ</td>
            <td>20€</td>
          </tr>
          <tr>
            <td>MAILLOT INTEGRAL</td>
            <td>30€</td>
          </tr>
          <tr>
            <p className="App-subtitle">
              – FORFAIT –
            </p>
          </tr>
          <tr>
            <td>1/2 JAMBE + MAILLOT SIMPLE + AISSELLES</td>
            <td>40€</td>
          </tr> 
          <tr>
            <td>1/2 JAMBE + MAILLOT INTEGRAL + AISSELLES </td>
            <td>55€</td>
          </tr> 
          <tr>
            <td>JAMBES COMPLÈTES + MAILLOT SIMPLE + AISSELLES</td>
            <td>50€</td>
          </tr> 
          <tr>
            <td>JAMBES COMPLÈTES + MAILLOT INTEGRAL + AISSELLES </td>
            <td>60€</td>
          </tr> 
        </table>
        <div>
          <img src={nails1} className="App-photo"/>
          <img src={nails2} className="App-photo"/>
        </div>
        <div>
          <a href="https://www.treatwell.fr/salon/victoria-nails-paris/" >
            <p className="">
              <b>
              Réservations: Treatwell.fr
              </b>
            </p>
          </a>
          <a href="tel: 0145314688"> 
            <p className="">
              <b>
                Tel: 01 45 31 46 88
              </b>
            </p>
          </a>
          <a href="https://facebook.com/victoria.nails.paris" >
            <img src={facebook} className="App-appstore" alt="Facebook" />
          </a>
          <a href="https://instagram.com/victoria.nails.paris" >
            <img src={instagram} className="App-appstore" alt="Instagram" />
          </a>
        </div>
        <p className="App-contact">
          Bienvenue!
        </p>
    </div>
  );
}

export default App;
